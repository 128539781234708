import React from "react";
import { Router, Route } from "react-router-dom";
import history from "./history";

import Home from "./views/Home";
import "./App.module.scss";

const App = () => {
  return (
    <div className="App">
      <Router history={history}>
        <Route path="/" exact component={Home} />
      </Router>
    </div>
  );
};

export default App;
