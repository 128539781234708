import React from "react";
import BaseLink from "../../component/BaseLink";

import styles from "./index.module.scss";

const Home = () => {
  return (
    <div className={styles.home}>
      <main className={styles.links}>
        <section>
          <BaseLink
            title="P2P Meshstream"
            url="https://mellifluous-selkie-322aa7.netlify.app"
          />
        </section>
        <section>
          <BaseLink
            title="Mesh Conference"
            url="https://conference-ee.meshstream.io/"
          />
        </section>
      </main>
    </div>
  );
};

export default Home;
