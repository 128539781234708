import React from "react";

import styles from "./index.module.scss";

const BaseLink = ({ title, url }) => {
  return (
    <a className={styles.link} href={url} target="_blank" rel="noreferrer">
      {title}
    </a>
  );
};

export default BaseLink;
